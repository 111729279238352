import { mapState } from 'vuex'
// import KnTabs from '@/components/KnTabs.vue'
import { addressUtilsMixin } from '../../../shared/mixins/addressUtilsMixin'
import { apiMixin } from '../../../shared/mixins/apiMixin'
import { fileUtilsMixin } from '../../../shared/mixins/fileUtilsMixin'
import { getItem, putItem } from "@/api/api-methods";
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin'

import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue'
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue'
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue'
import KnFormTitle from '../../../shared/components/KnFormTitle.vue'
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue'
import KnSelect from '../../../shared/components/KnSelect.vue'
import KnTextArea from '../../../shared/components/KnTextArea.vue'
import KnTextField from '../../../shared/components/KnTextField.vue'
export default {
  components: {
    KnFormSubtitle,
    KnTextField,
    KnSelect,
    KnLocalAlert,
    KnFormActionButtons,
    KnTextArea,
    KnFormTitle,
    KnBackToTopButton
  },
  mixins: [
    fileUtilsMixin,
    validationFormMixin,
    apiMixin,
    addressUtilsMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tabs: [
        { name: 'Detalle de la orden', value: 100 },
      ],
      valueDeterminate: 100,
      routerName: 'Ordenes',
      orderId: null,
      order: {
        numero_orden: null,
        id_estatus_orden: null,
        id_alumno: null,
        id_datos_facturacion: null,
        id_direccion: null,
        peso_volumetrico: null,
        sub_total: null,
        total_impuestos: null,
        costo_envio: 0.00,
        total_descuento: 0.00,
        total_orden: null,
        id_moneda: null,
        descuento_aplicado: false,
        fecha_elaboracion: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      products: [],
      orderStatus: [],
      students: [],
      shippingTypes: [],
      measurementUnits: [],
      coins: [],
      items: [],
      headers: [
        { text: 'Producto', value: 'producto.nombre_producto', class: 'purple--text' },
        { text: 'Peso volumétrico', value: 'producto.peso_volumetrico', class: 'purple--text' },
        { text: 'Cantidad', value: 'unidades', class: 'purple--text' },
        { text: 'Unidad de medida', value: 'unidad_medida.dato', class: 'purple--text' },
        { text: 'Precio unitario', value: 'precio_unitario', class: 'purple--text' },
        { text: 'Sub total', value: 'sub_total', class: 'purple--text' },
        { text: 'Impuestos', value: 'impuesto', class: 'purple--text' },
        { text: 'Total', value: 'total', class: 'purple--text' },
      ],

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Agregar orden' : 'Detalle de la orden'
    },
    isEditMode() {
      return this.entity !== null
    },
    successAlertText() {
      return this.isEditMode ? 'Estatus actualizado con éxito!' : 'Orden registrada con éxito!'
    },
    successAlertType() {
      return this.isEditMode ? 'info' : 'success'
    },
    enableBtnAccept() {
      return this.order.estatus_orden !== this.entity.estatus_orden.id
    },
  },
  async created() {
    try {
      // Set loading state
      this.loading = true;
      this.alertText = 'Por favor, espere. Cargando...';
      this.showAlert = true;
      this.alertType = 'info';

      // Fetch data concurrently
      const [
        orderStatusRes,
        studentsRes,
        shippingTypesRes,
        // productsRes,
        measurementUnitsRes,
        coinsRes
      ] = await Promise.all([
        getItem(`/app-ordenes/filters/mv-estatus-orden?estatus_sistema=true`),
        getItem(`/app-personas/filters/alumno?institucion_educativa=${this.institutionId}&estatus_sistema=true`),
        getItem(`/app-ordenes/filters/mv-tipo-envio?estatus_sistema=true`),
        // getItem(`/app-ordenes/filters/detalle-orden?orden=${this.order.id}&institucion_educativa=${this.institutionId}&estatus_sistema=true`),
        getItem(`/app-productos/filters/uom?institucion_educativa=${this.institutionId}`),
        getItem(`/app-administracion/filters/moneda?institucion_educativa=${this.institutionId}`)
      ]);

      this.orderStatus = orderStatusRes.results;
      this.students = studentsRes.results;
      this.shippingTypes = shippingTypesRes.results;
      // this.products = productsRes.results;
      this.measurementUnits = measurementUnitsRes.results;
      this.coins = coinsRes.results;

      if (this.isEditMode) {
        // console.log('Se deben llenar los campos');
        await this.fillData();
      } else {
        this.order.institucion_educativa = this.institutionId;
        this.order.autor = this.userData.id;
      }

      this.showAlert = false;
    } catch (error) {
      console.error('Error in created:', error);
      this.loading = false;
      this.alertText = 'Error al cargar datos. Por favor, inténtelo de nuevo.';
      this.alertType = 'error';
    }
  },

  methods: {
    setTabValue(val) {
      this.valueDeterminate = val
    },
    async updateOrderStatus() {
      try {
        // console.log(this.order);
        this.alertText = 'Actualizando estatus de orden'
        const statusObj = {
          ...this.order,
          estatus_orden: this.order.estatus_orden,
          datos_facturacion: this.order.datos_facturacion.id
        }
        const responseData = await putItem(`/app-ordenes/orden/${this.order.id}`, statusObj)
        if (responseData.e) {
          this.errors.push(responseData.e)
        }
      } catch (error) {
        console.error('Error al intentar actualizar el estatus de la orden', error);
        this.errors.push('No se pudo actualizar el estatus de la orden')
      }
    },
    async updateObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true

        await this.updateOrderStatus()

        this.loading = false
        if (this.errors.length > 0) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        console.error('Error al intentar actualizar inventario o crear movimiento inventario')
      }
    },
    async save() {
      if (this.isEditMode) {
        this.updateObjects()
      }
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName })
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    findProductPrice() {
      const product = this.products.find(p => p.id === this.productId)
      const productPrice = product.precios.find(pr => pr.distribuidor === this.institutionId)
      this.distributionPrice = productPrice.precio_distribuidor
      this.inventory.moneda = productPrice.moneda
    },
    async fillData() {
      try {
        // Fetching data concurrently
        const [address, details] = await Promise.all([
          getItem(`/app-personas/direccion/${this.entity.direccion_envio.id}`),
          getItem(`/app-ordenes/filters/detalle-orden?orden=${this.entity.id}&institucion_educativa=${this.institutionId}&estatus_sistema=true`)
        ]);

        // Process fetched data
        const fullAddress = this.fullAddressWithZipObj(address);
        const items = details.results.map(detail => {
          const subtotal = parseFloat(detail.sub_total);
          const taxRate = parseFloat(detail.impuesto);
          // console.log('taxRate');

          // if (Number.isInteger(taxRate)) taxRate = taxRate / 100;
          
          // const taxValue = subtotal * taxRate;
          const total = subtotal + taxRate;
          return {
            ...detail,
            total: total
          };
        });

        this.order = {
          id: this.entity.id,
          numero_orden: this.entity.numero_orden,
          estatus_orden: this.entity.estatus_orden.id,
          alumno: this.entity.alumno.id,
          datos_facturacion: this.entity.datos_facturacion,
          rfc: this.entity.datos_facturacion.tax_id,
          direccion: this.entity.direccion_envio.id,
          fullAddress: fullAddress,
          peso_volumetrico: this.entity.peso_volumetrico,
          sub_total: this.entity.sub_total,
          total_impuestos: this.entity.total_impuestos,
          tipo_envio: this.entity.tipo_envio.id,
          costo_envio: this.entity.costo_envio,
          total_descuento: this.entity.total_descuento,
          total_orden: this.entity.total_orden,
          moneda: this.entity.moneda.id,
          descuento_aplicado: false,
          fecha_elaboracion: this.formatDate(this.entity.fecha_elaboracion),
          autor: this.userData.id,
          institucion_educativa: this.institutionId
        };

        this.items = items;

      } catch (error) {
        console.error('Error in fillData:', error);
      }
    }


  }
}